import React from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import DefaultLayout from '../layouts/Default';
import styles from './static-page.module.scss';

export default ({ data }) => {
  const post = data.markdownRemark;

  return (
    <DefaultLayout>
      <div className={classnames(['card', styles.container])}>
        <h1>{post.frontmatter.title}</h1>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </DefaultLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        keywords
      }
    }
  }
`;
